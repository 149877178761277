body,
#root {
	min-height: 100vh;
}

#root {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

main {
	height: calc(100vh - 64px);
}
